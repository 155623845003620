.container{
    
    color: white;
    font-family: inherit !important;
    padding-bottom: 1rem;
    min-width: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    border-radius: 0 !important;

}

.footer{
    width: 67.37%;
}

.footer-group{
    padding: 0 0 0 6rem;
    text-align: left;
}

.footer-tittle{
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 1rem
}

.footer-subtittle{
    text-decoration: none;
    font-size: 1rem;
    display: block;
    color: white;
    line-height: 2rem;
}

.footer-subtittle:hover{
    text-decoration: underline;
    font-weight: 600;
    display: block;
    color: white;
    line-height: 2rem;
}
.logo-footer{
    width: 10rem;
}

@media only screen and (max-width: 600px) {
    .footer-tittle{
        padding-top: 2rem;
        font-size: 1rem;
        font-weight: 700;
    }

    .footer-subtittle{
        text-decoration: none;
        font-size: 0.8rem;
        display: block;
        color: white;
        line-height: 2rem;
    }

    .container{
        background-color: #415a95;
        color: white;
        font-family: inherit !important;
        padding: 0 2rem 1rem 0;
        min-width: 100vw;
    
    }

}

@media only screen and (min-width: 1800px) {
    .container{
       min-width: 99vw;
       background-color: #415a95;
        padding-bottom: 1rem;
    }
}