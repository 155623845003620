.part1_BusinessBanking{
    background-image: linear-gradient(to right,#02C5B0, #6D29AA);
    height: 60vh;
    color: white;
    font-size: 5rem;
    text-align: left;
    font-weight: 700; 
    display: flex;
    align-items: center;
    padding: 0 10rem;
    line-height: 5rem;
}

.part2_BusinessBanking{
    width: 70vw;
    padding: 8rem 0 8rem 10rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
}


.part3_BusinessBanking{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(235, 239, 240) 100%);
    text-align: left;
}

.part3_BusinessBanking h2{
    font-size: 3rem;
    font-weight: 600;
    padding: 3rem 2rem 5rem 9rem;
}

.part3_BusinessBanking_content,
.part11_Deposits_content{
    padding: 0 3rem 0 9rem;
}

.part3_BusinessBanking_content_text,
.part11_Deposits_content_text{
    text-align: left;
    font-size: 1.2rem;
    padding: 0 4rem 0 0;
}

.part3_BusinessBanking_content_text p,
.part11_Deposits_content_text p{
    font-size: 1.2rem;
}


.part3_BusinessBanking_content_img{
    padding: 0 5rem 0 0;
}

.part3_BusinessBanking_content_text button{
    background-color: #4FB645;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 3rem;
    letter-spacing: 1.5px;
    margin: 3rem 0;
    font-size: 1.5rem;
}


.part4_BusinessBanking h2{
    font-size: 3rem;
    font-weight: 600;
    padding-bottom: 5rem;
}


.part4_BusinessBanking_content_card div{
    background-color: white;
    padding: 3rem 3rem;
    box-shadow: rgba(0, 0, 0, 0.068) 10px 10px 10px;
}


.part4_BusinessBanking_content_card h3{
    font-size: 2.5rem;
    font-weight: 700;
}



.part4_BusinessBanking_content_card p{
    font-size: 1.2rem;
    padding:  3rem 0;
}


.part4_BusinessBanking{
    height: fit-content;
    width: 100vw;
    background: linear-gradient(180deg, rgba(255, 255, 255,1) 0%, rgba(235, 239, 240,1) 100%);
    padding: 5rem 10rem;
    text-align: left;
}



.part4_BusinessBanking h2 {
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 3rem;
}

.part4_BusinessBanking h4 {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1rem;
}


.part4_BusinessBanking_content_card h3 {
    font-size: 1.5rem;
    font-weight: 700;
}

.part4_BusinessBanking_content_card p{
    font-size: 1rem;
    padding: 1rem 0;
}


.part4_BusinessBanking {
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
    background: linear-gradient(180deg, rgba(255, 255, 255,1) 0%, rgba(235, 239, 240,1) 100%);
    padding: 1rem 3rem 4rem 3rem;
    text-align: left;
}

.part4_BusinessBanking {
    background: linear-gradient(0deg, rgba(255, 255, 255,1) 0%, rgba(235, 239, 240,1) 100%);
}

.part4_BusinessBanking_content_card div {
    height: 28rem;
}

.part4_BusinessBanking img {
    width: 5rem;
}