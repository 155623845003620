@media only screen and (min-width: 1600px) {
    .h110{
        height: fit-content;
    }

    .h100{
        padding: 0 12rem;
    }

    
}