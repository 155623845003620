.firstpart-lending {
  font-family: 'Courier New', Courier, monospace !important;
  padding: 2rem 5rem;
  position: relative;
}

.firstpart-lending h1 {
  text-align: left;
  line-height: 7rem;
  font-size: 6rem;
  font-weight: bolder;
  font-family: monospace !important;
  padding-bottom: 1rem;
}

.firstpart-lending p {
  text-align: left;
  line-height: 2rem;
  font-size: 1.2rem;
  font-family: sans-serif !important;
}


.firstpart-lending button {
  background-color: #00B240;
  color: white;
  font-family: monospace !important;
  font-size: 1.2rem;
  letter-spacing: 1px;
  padding: 0.6rem 1rem;
  border-radius: 3rem;
  position: absolute;
  left: 0;
  margin-top: 1.5rem;
}

.firstpart-lending-right {
  padding: 0 0 2rem 2rem;
}

/* Part 2 */

.img-part2 {
width: 100%;
display: flex;
justify-content: flex-start;
flex-direction: row;
}

.secondpart-businesslending {
  padding: 5rem 5rem;
  margin-left: 0 !important;
  margin-right: 0px !important;
  row-gap: 0px !important;
  background-color: #212121;
}

.secondpart-businesslending h1 {
  color: white;
  font-family: sans-serif !important;
  font-size: 3.5rem;
  font-weight: bolder;
}

.secondpart-businesslending h2 {
  color:#00B240;
  font-family: sans-serif !important;
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: thinner;
}

.secondpart-businesslending .line {
  height: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.secondpart-businesslending .line>div {
  background-color: #00B240;
  height: 0.2rem;
  width: 10%;
}

.part2-card h2 {
  margin: 1rem 0;
  font-family: sans-serif !important;
  font-size: 1.8rem;
  font-weight: bolder;
}

.part2-card p {
  font-family: sans-serif !important;
  font-size: 1.2rem;
}


.part2-card{
  padding: 2rem 1rem;
  height: 20rem;
  background-color: aliceblue;
  text-align: left;
  border-radius: 0.5rem;
}


/* Part3 */
.part3-businesslending{
  padding: 5rem 20rem;
}

.part3-businesslending h1{
  font-family: sans-serif !important;
  font-size: 3rem;
  font-weight: bolder;
}

.part3-businesslending h2{
  color:#00B240;
  font-family: sans-serif !important;
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: thinner;
}

.part3-businesslending .line{
  height: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}

.part3-businesslending .line>div{
  background-color: #00B240;
  height: 0.2rem;
  width: 10%;
}

.part3-businesslending p{
  font-family: sans-serif !important;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

/* Part 4 */
.part4-businesslending{
  padding: 0 10rem;
  margin-bottom: 5rem;
}

.businesslending-swyper-content{
  padding: 4rem 0;
}

.part4-businesslending-left{
  padding-left: 3rem;
  text-align: left;
}

.part4-businesslending-left h1{
  font: 2rem sans-serif;
  font-weight: bolder;
  padding: 0 2rem 2rem 1rem;
}

.part4-businesslending-left .anticon svg{
  font-size: 1.5rem;
  width: 3rem;
  color: #00B240;
}

.part4-businesslending-left p{
  font:1rem sans-serif;
  line-height: 2.5rem;
}

.part4-businesslending-right{
  padding: 0 2rem 2rem 2rem;
}

.part5-swiper-content{
  padding: 7rem 7rem;
}

.part5-swiper-content .flex{
  width: 100%;
}

.part5-swiper-content .flex h3{
  font: 3rem sans-serif;
  font-weight: bolder;
      background: -webkit-linear-gradient(45deg, #087674, #00B240 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 2rem 0;
}

.part5-swiper-content .flex h4{
  font: 1.5rem sans-serif;
  line-height: 2rem;
  font-weight: bolder;
}