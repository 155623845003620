/* .banner{
    background-image: url("../../assets/seedoflife.png");
    background-repeat: no-repeat;
    background-position: -10rem;
    background-size: 30rem;
    background-position-y: 10rem; 
    
} */

.after-slider {
    background-image: url("../../assets/floweroflife.png");
    background-repeat: no-repeat;
    background-size: 30rem;
    background-position: center;
}

.Mambubythenumbers {

    background-image: url("../../assets/floweroflife.png");
    background-repeat: no-repeat;
    background-size: 30rem;
    background-position: center;

}

.top-home,.gradient-bar {
    /* background: linear-gradient(to right,#7926F0, #03BD66), url(https://grainy-gradients.vercel.app/noise.svg); */
    /* background: #170F58; */
    background: rgb(79,182,69);
background: linear-gradient(90deg, rgba(8,56,99) 5%, rgba(79,182,69,1) 50%);
    border-radius: 1rem;
}

.swiper-button-next,.swiper-button-prev{
    color: rgb(79,182,69) !important;
}

@media (min-width: 360px) {
    @media not all and (min-width: 768px) {
        .top-home {
            /* background: linear-gradient(to right,#7926F0, #03BD66), url(https://grainy-gradients.vercel.app/noise.svg); */
            /* background: #170F58; */
            background: rgb(79,182,69);
        background: linear-gradient(45deg, rgba(23,15,88,1) 2%, rgba(79,182,69,1) 30%);
            border-radius: 1rem;
        }
        
    }

}

.top-home-img {
    background-image: url("../../assets/HomePage/tophome-img.png");
    background-repeat: no-repeat;
    background-size: contain;
}

/* .banner-content {
    background: url("../../assets/Artboard\ 1.svg");
    background-repeat: no-repeat;
    background-size: 20rem;
    background-position: center;
} */

/* .floweroflife-cardtabs {
    background: url("../../assets/Artboard\ 1.svg");
    background-repeat: no-repeat;
    background-size: 20rem;
    background-position: center;
} */


.sustain-carousel .slick-dots-bottom li {
    background-color: #170F58;
}

.sustain-carousel .slick-dots-bottom .slick-active button {
    background-color: rgb(42, 165, 52) !important;
}

.sustain-seedoflife {
    background: url("../../assets/HomePage/seedoflife.png");
    background-repeat: no-repeat;
    background-size: 20rem;
    background-position: center;
}

.solution-intro-container {
    background: url("../../assets/seedoflife142.svg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 25%;
}

/* .endsolutions {
    background: url("../../assets/seedoflife141.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 25%;
} */


.part1-home {
    background: url("../../assets/seedoflife121.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.Baas-bg {
    background: url("../../assets/seedoflife121.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20%;
}

.slider-company-container{
     background: url("../../assets/seedoflife122.svg");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
}

/* .banner-fitimg{
    background: url("../../assets/Artboard\ 1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */

.trigger-img {
    background: url("../../assets/Artboard\ 1.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center ;
}

.endpage{
    background: url("../../assets/seedoflife141.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 20%;
}


@media only screen and (max-width: 1400px) {
    .trigger-img {
        background: url("../../assets/Artboard\ 1.png");
        background-repeat: no-repeat;
        background-size:90%;
        background-position: center left;
    }
}

@media only screen and (max-width: 1000px) {
    .trigger-img {
        background: url("../../assets/Artboard\ 1.png");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center ;
    }
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
    .trigger-img {
        background: url("../../assets/Artboard\ 1.png");
        background-repeat: no-repeat;
        background-size:contain;
        background-position: center left ;
    }
}


@media only screen and (min-width: 1600px) {
    .bankname {
        font-size: 2.6rem;
    }

    .bankdescription {
        font-size: 1.1rem;
    }

    .banner-content {
        font-size: 1.1rem;
    }

    .slider-banner-container {
        height: fit-content;
    }


    .solution-card-img {
        width: 70%;
        height: 80%;
    }

    .solution {
        height: 20rem;
    }

    .banner-content {
        background: url("../../assets/HomePage/seedoflife.png");
        background-repeat: no-repeat;
        background-size:0;
        background-position: center;
    }

    
/* 
    .floweroflife-cardtabs {
        background: url("../../assets/Artboard\ 2.svg");
        background-repeat: no-repeat;
        background-size: 25rem;
        background-position: center;
    } */



    .sustain-des {
        font-size: 1.2rem;
    }

    .sustain-title {
        font-size: 4rem;
    }

    .sustain-seedoflife {
        background: url("../../assets/HomePage/seedoflife.png");
        background-repeat: no-repeat;
        background-size: 30rem;
        background-position: top;
    }

    /* .slider-endsolutions{
        background: url("../../assets/HomePage/triangleflip.png");
        background-repeat: no-repeat;
        background-size: 30rem;
        background-position: right center;
    } */
    .sustain-img-container {
        height: fit-content;
    }

    .sustain-img-container img {
        height: 50vh;
    }

    
    /* .part1-home {
        margin-top: 5.5rem;   
        margin-bottom: 6.5rem;
    } */

    .part1-home {
        background: url("../../assets/seedoflife121.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
    }

    .solution-intro-container {
        background: url("../../assets/seedoflife142.svg");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 20%;
    }

    /* .endsolutions {
        background: url("../../assets/seedoflife141.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    } */

    /* .banner-fitimg{
        background: url("../../assets/Artboard\ 1.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    } */

}